<template>
  <div class="support">
    <div class="section section-1">
      <b-container>
        <b-row class="align-items-center">
          <b-col cols="12" class="text-center">
            <h2 class="title">FAQ</h2>
            <p class="mini-title text-center">
              During your experience at ESB FX, all your questions are answered here.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-2">
      <b-container>
        <FAQ />
      </b-container>
    </div>
    <div class="section section-2">
      <Started />
    </div>
  </div>
</template>

<script>
import Started from '@/components/shared/Started.vue';
import FAQ from '@/components/shared/FAQ.vue';

export default {
  components: { Started, FAQ },
};
</script>

<style lang="scss">
.support {
  h2.title {
    font-size: clamp(1.2rem, 3vw, 2.2rem);
  }
  .section-1 {
    padding: 150px 0 20px;
    background: #f2f4f6;
    position: relative;
    @media (max-width: 1440px) {
      padding: 120px 0 20px;
    }
  }
  .section-2 {
    padding: 20px 0 10px;
    position: relative;
    padding: 1rem;
    margin: 1rem -15px 0;
    border: solid #f8f9fa;
    border-width: 0.2rem 0 0;
    .container {
      max-width: 768px;
    }
  }
}
</style>
